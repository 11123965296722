import React from "react"
import Layout from "@/components/layout"
import FormularioBolsa from "@/components/FormularioBolsa"

const SucessPage = () => {
  return (
    <Layout>
      <FormularioBolsa />
    </Layout>
  );
};

export default SucessPage;
