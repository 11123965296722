/** @jsx jsx */
import { css } from '@emotion/react'

export const titleEvent = css`
  color: #ffffff;
  text-align: center;
  font-size: 54px;
  font-weight: 700;
  line-height: 100%;
  width: 100%;
  margin: auto;
  margin-bottom: 12px;
`


export const subtitleEvent = css`
    font-weight: 300 !important;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin: auto;

    span{
        font-weight: 600;
        font-size: 44px;
    }
`

export const content = css`
    padding: 122px 0;
    a{

        padding-left:16px;
        padding-right: 16px;
        img{
        width: 26px;
        filter: brightness(0) invert(1);
        margin-right: 8px;
        }
    }
   
`